import { differenceInHours, isToday, parseISO, toDate } from "date-fns";
import { action, computed, thunk } from "easy-peasy";
import easyState from "lib/easyState";
import { concat, isEmpty, isNil, map, pull, reject } from "lodash";
import { fetchDistrictsList } from "services/apiDistricts";
import { fetchSchoolsList } from "services/apiSchools";
import history, {
  DISTRICT_ALL_SLUG,
  DISTRICT_SLUG_INDEX,
  isUnscopedPath,
  SCHOOL_SLUG_INDEX
} from "../history";

export const ROUTE_STATUSES = {
  onTime: "on_time",
  late: "late",
  started: "started"
};

export const ROUTE_HOURS_THRESHOLD = 2;

const appStore = {
  ...easyState("school"),
  ...easyState("allSchools", false),
  ...easyState("filtersReset", false),

  nowWithTz: computed((state) => (schoolId, date = null) => {
    if (!schoolId) return [null, null];
    // need to take today date with current time
    const current = new Date();
    const dateLocal = date && !isToday(date) ? date : current;
    const school = state.getSchool(schoolId);
    if (!dateLocal || !school) return [null, null];
    // take now in school time zone (to get correct date for today)
    let now = new Date(
      current.getTime() + school.time_zone_offset * 1000 + current.getTimezoneOffset() * 60 * 1000
    );
    return [dateLocal, now];
  }),

  isFutureDate: computed((state) => (schoolId, date = null) => {
    const [dateLocal, now] = state.nowWithTz(schoolId, date);
    if (!dateLocal || !now) return false;

    // set end of the day
    now.setHours(23, 59, 59, 0);
    return toDate(dateLocal).getTime() > now.getTime();
  }),

  isPastDate: computed((state) => (schoolId, date = null) => {
    const [dateLocal, now] = state.nowWithTz(schoolId, date);
    if (!dateLocal || !now) return false;

    // set beginning of the day
    now.setHours(0, 0, 0, 0);
    return toDate(dateLocal).getTime() < now.getTime();
  }),

  routeStartStatus: computed((state) => (schoolId, date = null, startTime = null) => {
    if (state.isFutureDate(schoolId, date)) return ROUTE_STATUSES.onTime;
    if (state.isPastDate(schoolId, date)) return ROUTE_STATUSES.started;
    if (!startTime) return ROUTE_STATUSES.onTime;

    const now = new Date();
    const startAtTime = parseISO(startTime);
    if (startAtTime.getTime() <= now.getTime()) return ROUTE_STATUSES.started;

    const diffHours = differenceInHours(startAtTime, now);
    return diffHours >= ROUTE_HOURS_THRESHOLD ? ROUTE_STATUSES.onTime : ROUTE_STATUSES.late;
  }),

  // The onChangeScope callback should be a single ref (created with a useCallback)
  // per page. We call it when running the changeSchool or changeDistrict, so we can reset state on the
  // current page rendered.
  // Important that callback should use static values bc link to it will be permanents or
  // same argument list that was passed to callback should be passed to useEffect, but this
  // may cause some extra rerenders.
  // Usage:
  //    const resetState = useCallback(() => { ... }, []);
  //    useEffect(() => {
  //      onChangeScope(resetState);  // set once
  //      return () => onChangeScope(null);  // always clear after
  //    }, []);
  //
  changeScopeCb: null,
  changeSchool: thunk(async (actions, id, h) => {
    const state = h.getState();
    // we need her comparison by == to compare undefined with null
    if (state.school?.id == id && !state.allSchools) return;

    const schools = state.schools;
    const school = schools.find((s) => s.id == id);
    actions.setSchool(school);

    // handle all schools option
    const allSchools = id === "all";
    actions.setAllSchools(allSchools);

    // NOTE: uncomment this if we need to change district on school change
    // const district = school?.district_id
    //   ? state.districts.find((d) => school.district_id === d.id)
    //   : null;
    // const district_slug = (school ? district?.slug : state.district?.slug) || DISTRICT_ALL_SLUG;
    // if (school) actions.setDistrict(district);

    const pathname = history.location.pathname;
    if (!isUnscopedPath(pathname)) {
      const path = pathname.split("/");
      // NOTE: uncomment this if we need to change district on school change
      // if (path[DISTRICT_SLUG_INDEX] !== district_slug) {
      //   path.splice(DISTRICT_SLUG_INDEX, path.length > DISTRICT_SLUG_INDEX ? 1 : 0, district_slug);
      // }
      if (
        path.length > SCHOOL_SLUG_INDEX &&
        schools.find((s) => s.slug === path[SCHOOL_SLUG_INDEX])
      ) {
        path[SCHOOL_SLUG_INDEX] = school?.slug; // replace slug
      } else {
        path.splice(SCHOOL_SLUG_INDEX, 0, school?.slug); // inject slug
      }
      history.push({
        pathname: reject(path, isNil).join("/"),
        search: history.location.search
      });
    }

    const cb = state.changeScopeCb;
    if (cb) cb({ school, allSchools });
  }),

  onChangeScope: action((state, ref) => {
    if (state.changeScopeCb !== ref) {
      state.changeScopeCb = ref;
    }
  }),

  schoolRequired: thunk((actions, val, h) => {
    if (!h.getState().school) {
      actions.setFlashMessage({ message: "School required", type: "error" });
      return true;
    }
    return false;
  }),

  updateScopes: thunk((actions, params = {}, h) => {
    const { userId, force } = params;
    const state = h.getState();
    if (force || state.user?.id === userId) {
      actions.updateDistricts();
      actions.updateSchools();
    }
  }),

  ...easyState("schools", []),
  updateSchools: thunk(async (actions, _val) => {
    const resp = await fetchSchoolsList();
    actions.setSchools(resp.schools);
  }),
  getSchool: computed((state) => (id) => {
    if (state.school?.id == id) return state.school;
    return state.schools.find((s) => s.id == id);
  }),
  districtSchools: computed((state) => {
    if (!state.district) return state.schools;
    return state.schools.filter((s) => s.district_id === state.district.id);
  }),

  ...easyState("district"),

  // The changeDistrict callback should be a single ref (created with a useCallback)
  // per page. We call it when running the changeDistrict, so we can reset state on the
  // current page rendered.
  // Important that callback should use static values bc link to it will be permanents or
  // same argument list that was passed to callback should be passed to useEffect, but this
  // may cause some extra rerenders.
  // Usage:
  //    const resetState = useCallback(() => { ... }, []);
  //    useEffect(() => {
  //      onChangeDistrict(resetState);  // set once
  //      return () => onChangeDistrict(null);  // always clear after
  //    }, []);
  //
  changeDistrictCb: null,
  changeDistrict: thunk(async (actions, id, h) => {
    const state = h.getState();
    // we need her comparison by == to compare undefined with null
    if (state.district?.id == id) return;

    const districts = state.districts;
    const district = districts.find((s) => s.id == id);
    const school = state.school;
    actions.setDistrict(district);
    actions.setSchool(null);

    const pathname = history.location.pathname;
    if (!isUnscopedPath(pathname)) {
      const path = pathname.split("/");
      if (
        path.length > DISTRICT_SLUG_INDEX &&
        (path[DISTRICT_SLUG_INDEX] === DISTRICT_ALL_SLUG ||
          districts.find((s) => s.slug === path[DISTRICT_SLUG_INDEX]))
      ) {
        path[DISTRICT_SLUG_INDEX] = district?.slug || DISTRICT_ALL_SLUG; // replace slug
      } else {
        path.splice(DISTRICT_SLUG_INDEX, 0, district?.slug || DISTRICT_ALL_SLUG); // inject slug
      }

      if (school && path.length > SCHOOL_SLUG_INDEX) path[SCHOOL_SLUG_INDEX] = null;

      history.push({
        pathname: reject(path, isNil).join("/"),
        search: history.location.search
      });
    }

    const cb = state.changeScopeCb;
    if (cb) cb({ district });
  }),

  onChangeDistrict: action((state, ref) => {
    if (state.changeDistrictCb !== ref) {
      state.changeDistrictCb = ref;
    }
  }),

  ...easyState("districts", []),
  updateDistricts: thunk(async (actions, _val) => {
    const resp = await fetchDistrictsList();
    actions.setDistricts(resp.districts);
  }),
  getDistrict: computed((state) => (id) => {
    return state.districts.find((s) => s.id == id);
  }),

  ...easyState("user"),
  isUserAdmin: computed((state) => state.user?.role === "admin"),
  isUserSchoolStaff: computed((state) => state.user?.role === "school_staff"),
  isRestrictedAdmin: computed((state) => state.user?.restricted_admin),
  readOnly: computed((state) => state.user?.read_only),
  hasDistrict: computed((state) => !isEmpty(state.districts)),
  hasMultipleSchools: computed((state) => state.isUserAdmin || state.user?.with_schools),
  hasCommsAccess: computed((state) => {
    return (
      state.isUserAdmin || (state.user?.role === "school_staff" && state.user?.communication_access)
    );
  }),

  ...easyState("flashMessage"),
  ...easyState("selectedTab", null),
  ...easyState("selectedSubTab", null),

  ...easyState("reportJIDs", []),
  addReportJIDs: action((state, jids) => {
    state.reportJIDs = concat(state.reportJIDs, map(jids, "jid"));
  }),
  removeReportJID: action((state, jid) => {
    pull(state.reportJIDs, jid);
  }),
  jidValid: computed(
    (state) => (jid, userId) => state.reportJIDs.indexOf(jid) > -1 || userId === state.user.id
  ),

  initialState: action((state, props) => {
    Object.entries(props).forEach(([key, val]) => {
      state[key] = val;
    });
  })
};

export default appStore;
